import Agent from "../../../Interfaces/agent";
import { FieldTypes } from "../../../utils/fieldTypes";

const RecentlyMatchedAgentDefaultFields = (agent: Agent, index: number) => {
  const fields = [
    {
      type: FieldTypes.header,
      keyName: `agentHeader${index}`,
      label: `${agent.firstname} ${agent.lastname} ${
        agent.brokerage_name ? `of ${agent.brokerage_name}` : ""
      }`,
    },
    {
      label: `Did you speak with ${agent.firstname} ${agent.lastname} via text, email, or phone call?`,
      keyName: `did_you_speak_with_agent_${
        index + 1
      }__via_text__email__or_phone_call___lost_`,
      type: FieldTypes.radioText,
      options: ["Yes", "No"],
    },
    {
      label: `Did you meet with ${agent.firstname} ${agent.lastname}?`,
      keyName: `did_you_meet_with_agent_${index + 1}___lost_`,
      type: FieldTypes.radioText,
      options: ["Yes", "No"],
      triggers: {
        [`did_you_speak_with_agent_${
          index + 1
        }__via_text__email__or_phone_call___lost_`]: "Yes",
      },
    },
    {
      label: `How likely are you to recommend ${agent.firstname} ${agent.lastname} to a friend?`,
      keyName: `how_likely_are_you_to_recommend_agent_${
        index + 1
      }_to_a_friend___lost_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Our notes currently show that you're going in a different direction. Is that still the case? If so, what could ${agent.firstname} ${agent.lastname} have done to win your business?`,
      keyName: `are_you_still_lost__if_so__what_could_agent_${
        index + 1
      }_have_done_to_win_your_business_`,
      type: FieldTypes.textarea,
      triggers: {
        [`how_likely_are_you_to_recommend_agent_${
          index + 1
        }_to_a_friend___lost_`]: ["4", "5"],
      },
    },
    {
      label: `Why wouldn't you recommend ${agent.firstname} ${agent.lastname} to a friend?`,
      keyName: `why_wouldn_t_you_recommend_agent_${
        index + 1
      }_to_a_friend___lost_`,
      type: FieldTypes.textarea,
      triggers: {
        [`how_likely_are_you_to_recommend_agent_${
          index + 1
        }_to_a_friend___lost_`]: ["3", "2", "1"],
      },
    },
  ];

  return fields;
};

const RecentlyMatchedGeneralFields = () => [
  {
    type: FieldTypes.header,
    keyName: "generalHeader",
    label: `General Questions`,
  },
  {
    label:
      "Based on the agent(s) provided, how did their service compare to your expectations?",
    keyName:
      "based_on_the_agent_s__provided__how_did_their_service_compare_to_your_expectations___lost_",
    type: FieldTypes.radioNumber,
    options: ["1", "2", "3", "4", "5"],
  },
  {
    label: "Are you still planning to sell / buy?",
    keyName: "are_you_still_planning_to_sell___buy___lost_",
    type: FieldTypes.radioText,
    options: ["Yes", "No"],
  },
  {
    label: "Are you still planning on using an agent?",
    keyName: "are_you_still_planning_on_using_an_agent___lost_",
    type: FieldTypes.radioText,
    options: [
      "Yes, a traditional, full-commission agent",
      "Yes, a discount agent",
      "No, for sale by owner",
    ],
    triggers: {
      are_you_still_planning_to_sell___buy___lost_: "Yes",
    },
  },
  {
    label: `Did you know Clever is happy to introduce additional agents if the initial selection wasn't the right fit?`,
    keyName: "did_you_know_clever_is_happy_to_introduce_additional_agents_",
    type: FieldTypes.radioText,
    options: ["Yes", "No"],
    triggers: {
      are_you_still_planning_on_using_an_agent___lost_: [
        "Yes, a traditional, full-commission agent",
        "Yes, a discount agent",
      ],
    },
  },
  {
    label: "Would you like us to make another introduction to a Clever Agent?",
    keyName:
      "would_you_like_us_to_make_another_introduction_to_a_clever_agent___lost_",
    type: FieldTypes.radioText,
    options: ["Yes", "No"],
  },
  {
    label: `Would you try Clever again in the future? (We're nationwide and help buyers too!)`,
    keyName: "would_you_try_clever_again_in_the_future_",
    type: FieldTypes.radioText,
    options: ["Yes", "No", "Maybe"],
  },
  {
    label: `How would you rate Clever's communication after you were matched with your agent(s)?`,
    keyName:
      "how_would_you_rate_clever_s_communication_after_you_were_matched_with_your_agent_s____lost_",
    type: FieldTypes.radioNumber,
    options: ["1", "2", "3", "4", "5"],
  },
  {
    label: `Regarding Clever's Communication - What could have been better?`,
    keyName:
      "regarding_clever_s_communication___what_could_have_been_better____lost_",
    type: FieldTypes.textarea,
    triggers: {
      how_would_you_rate_clever_s_communication_after_you_were_matched_with_your_agent_s____lost_:
        ["1", "2", "3", "4"],
    },
  },
  {
    label: `How helpful was our concierge team?`,
    keyName: "how_helpful_was_our_concierge_team___lost_",
    type: FieldTypes.radioNumber,
    options: ["1", "2", "3", "4", "5"],
  },
  {
    label: "How likely are you to refer a friend to Clever?",
    keyName: "how_likely_are_you_to_refer_a_friend_to_clever___lost_",
    type: FieldTypes.radioNumber,
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  },
  {
    label: `What were you looking for that Clever wasn't able to provide?`,
    keyName:
      "what_were_you_looking_for_that_clever_wasn_t_able_to_provide___lost_",
    type: FieldTypes.textarea,
    required: true,
    triggers: {
      how_likely_are_you_to_refer_a_friend_to_clever___lost_: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
      ],
    },
  },
];

const getFields = (agents: Agent[]) => {
  let fields = [] as any[];
  const agentFields = agents
    .slice(0, 2)
    .map((agent, index) => RecentlyMatchedAgentDefaultFields(agent, index))
    .flat();
  const generalFields = RecentlyMatchedGeneralFields();
  fields = [agentFields, generalFields].flat();
  return fields;
};

export { getFields };
