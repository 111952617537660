import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import LostContainer from "../components/FormBuilder/LostContainer/LostContainer";
import "../styles/form.scss";

const Lost = () => (
  <AuthenticationLayout>
    <LostContainer />
  </AuthenticationLayout>
);

export default Lost;
